@-webkit-keyframes slide {
  100% {
    left: 50%;
  }
}

@keyframes slide {
  100% {
    left: 50%;
  }
}

.cmp-spinner.hidden {
  opacity: 0;
  pointer-events: none;
}

.cmp-spinner>* {
  position: absolute;
  transform: translate(-50%, -50%);
}

.cmp-spinner #dog-running {
  left: -50%;
  top: 45%;
  width: 100px;
  animation: slide 1.5s forwards;
  -webkit-animation: slide 1.5s forwards;
}

.cmp-spinner #ring {
  left: -50%;
  top: 50%;
  width: 100px;
  animation: slide 1s forwards;
  -webkit-animation: slide 1s forwards;
}

.cmp-spinner #spinner-text {
  left: 50%;
  top: 55%;
  font-family: "HemiHead";
  font-size: 24px;
  color: #233162;
}