@font-face {
  font-family: "RobotoMedium";
  src: local("Roboto"),
    url("./fonts/Fira_Sans\,Roboto\,Work_Sans/Roboto/Roboto-Medium.ttf")
      format("truetype");
}

@font-face {
  font-family: "LondrinaLight";
  src: local("LondrinaLight"),
    url("./fonts/Londrina/LondrinaSolid-Light.ttf") format("truetype");
}

@font-face {
  font-family: "OpenSans";
  src: local("LondrinaLight"),
    url("./fonts/Open_Sans/static/OpenSans-Regular.ttf") format("truetype");
}

.App {
  text-align: center;
  margin-block-end: 24px;
  margin-block-start: 24px;
}

.css-1dimb5e-singleValue {
  width: 190px;
}

.css-13cymwt-control {
  max-width: 250px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.input-wrapper {
  width: 300px;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;
  padding: 4px;

  &:hover {
    border-color: #40a9ff;
  }

  &.focused {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.85);
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
}

.styled-tag {
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
}

.event-form-grid {
  justify-content: space-between;
  align-items: flex-start;
  padding-inline: 24px;
  font-family: "RobotoMedium";
  border: 1px solid rgb(11, 87, 157);
  margin-inline: 12px;
  border-radius: 12px;
  padding: 12px;
}

.event-form-outer-stack {
  justify-content: center;
  padding-inline: 28px;
  padding-block-start: 28px;
  padding-block-end: 12px;
  width: 300px;
  margin: 0 auto;
  text-align: center;
}

.list-primary-box {
  background-color: white;
  height: fit-content;
  position: sticky;
  top: 0;
  z-index: 2;
}

.label {
  font-family: "RobotoMedium";
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
}

.list-box {
  width: 300px;
  overflow: scroll;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #fff;
  max-height: 75px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected="true"] {
    background-color: #fafafa;
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li[focused] {
    background-color: #e6f7ff;
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
}

.root-div {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

.title {
  font-family: "RobotoMedium";
}

.default-font {
  font-family: "RobotoMedium";
}

.paper {
  background-color: #383535;
  width: -webkit-fill-available;
}

.outer-box {
  display: grid;
  min-width: fit-content;
}

.results-grid-main {
  height: 100vh;
  width: -webkit-fill-available;
  position: fixed;
  top: 0;
  left: 0;
}

.rater {
  margin: 0 auto;
  display: inline-flex;
  position: relative;
  cursor: pointer;
}

.css-1wa3eu0-placeholder {
  font-family: "RobotoMedium";
}

.css-2b097c-container {
  font-family: "RobotoMedium";
}

.MuiAutocomplete-inputRoot {
  max-height: 100px;
  overflow: auto;
}

.MuiOutlinedInput-notchedOutline {
  position: fixed;
  /* width: 320px; */
  top: 70%;
  left: 20px;
  max-height: 100px;
  padding: none;
}

.delete-modal {
  width: max-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  background: white;
  border-radius: 12px;
  display: inline-block;
  text-align: center;
  border: 1px solid lightgrey;
  box-shadow: 24;
  padding: 24px;
}

.invite-friends-inner-button {
  background: rgba(35, 49, 98, 0.9);
  padding: 0.5em 1em;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  position: absolute;
  left: 10px;
  bottom: 0;
  border: solid 1px darkgrey;
  border-bottom: 0;
  align-items: center;
}

.event-card-name-grid {
  justify-content: space-between;
  align-items: center;
  padding-inline: 24px;
  margin-block-start: 12px;
  display: block;
}

.store-front-icon {
  color: #233162;
  height: 25px;
  margin: 0 5px -9px 0;
}

.event-card-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 350px;
}

.add-icon-button {
  width: 18px;
  height: 18px;
  color: white;
  border-radius: 18px;
  margin: -4px 8px -4px 0;
}

.grid-container-card-modal {
  justify-content: space-between;
  gap: 12px;
  align-items: center;
  margin-block-start: 12px;
  display: inline-flex;
  width: 350px;
}

.grid-content-card-modal {
  justify-content: center;
  align-items: center;
  display: flex;
}

.css-9emuhu-MuiPaper-root-MuiDrawer-paper {
  border-radius: 24px 24px 0 0;
  box-shadow: none;
}

.css-3su884-MuiPaper-root {
  padding-inline: 2px;
}

.logo-box {
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  height: fit-content;
  border-radius: "8px";
  z-index: 2;
  position: absolute;
  color: #233162;
  pointer-events: none;
}

.results-grid-item {
  height: 100vh;
  display: contents;
  z-index: 1;
  position: absolute;
  top: 0;
}

#root {
  height: calc(100vh - 56px);
  width: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
