.self-marker {
    padding-bottom: 61px;
}

.event-icon {
    height: 50px;
}

.dot-icon {
    height: 20px;
}